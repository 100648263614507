.light{
  background-color: white;
}

.dark {
  background-color: black;
}

.App {
  text-align: center;
  transition: all 5s;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
.MuiGrid-root{
 border-width:3;
 border-color:"rgba(0,0,0,0.5)";
 border-radius: 2.5;

}
.tinyText {
  font-size: 0.5rem;
}

.topBarbuttons{
  margin-left: 2rem;
}

.homeGrid {
  color:"text.primary";
  background-color:"background.default";
  margin-top:".5rem";
}

.skilsComponent {
  min-width:"50%";
  height:"100%";
  margin:"auto";
  margin-top:50 ;
  max-width:"70%"; 
}
.homeText {
  font-family:"roboto";
  margin-top:50;
}

.contactSubmitButton {
  margin-top: 20;
}

.logo img{
  margin: 10;
}