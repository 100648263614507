
.Contact_container{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: auto;
    max-width: 90%;
}
.Projects_Page_Container{
    align-content:center;
    display: flex;
    margin: auto;
    margin-top: 1rem;
    flex-direction: column;
}

.Projects_Container {
    margin:auto;
    margin-top: 1rem;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.Project_container {
    margin-top: .4rem;
    margin:auto;
    justify-content: center;
    align-content: center;
    width: 80%;
    
}

.project_text_content {
    margin-top: 5rem;
}

.Project_images_container {
    justify-content: center;
    align-content: center;
    margin: auto;
    min-width:40%;
    flex-direction: column;
    height: fit-content;
    overflow: visible;
    z-index: 999;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease;


}

.Project_image {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    z-index: -1;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease;
    transform: scale(0.95);
}


.Home_page_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Home_page_Grid{
    margin: auto;
    margin-top: 2rem;
    display: flex;
    width: 90%;
}